import React, {
  useEffect, useMemo, useState,
} from 'react';

import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

import ConfigContext from './utils/ConfigContext/ConfigContext';
import { getAuthClient } from './utils/auth';
import {
  getSettings,
  createContainerStyleMarkup,
  setDocumentStyleProperties,
  checkIfLoginNeeded,
} from './AppHelpers';
import CacheBuster from './CacheBuster';

import AppSwitchboard from './Components/AppSwitchboard/AppSwitchboard';
import './GlobalScss/global.scss';
import './App.scss';

const auth = getAuthClient();

const App = ( { history } ) => {
  const [ mapConfig, setMapConfig ] = useState( {} );
  const [ ytConfig, setYtConfig ] = useState( {} );
  const [ crmConfig, setCrmConfig ] = useState( {} );
  const [ personalizationActive, setPersonalizationActive ] = useState( false );
  const [ featureFlags, setFeatureFlags ] = useState( null );
  const [ unitHasDisplayableFees, setUnitHasDisplayableFees ] = useState( false );
  const [ defaultLevelOfCareUuid, setDefaultLevelOfCareUuid ] = useState( null );
  const [ containerStyle, setContainerStyle ] = useState( null );
  const [ isLoggedIn, setIsLoggedIn ] = useState( false );
  const [ salespersonId, setSalespersonId ] = useState( null );
  const [ salespersonNid, setSalespersonNid ] = useState( null );
  const [ salespersonName, setSalespersonName ] = useState( null );
  const [ allowLoginAccess, setAllowLoginAccess ] = useState( false );
  const [ isAuthed, setIsAuthed ] = useState( false );
  const [ isLoggingIn, setIsLoggingIn ] = useState( false );
  const [ appStatus, setAppStatus ] = useState( 'loading' );

  const settings = getSettings( window );
  const {
    isKiosk,
    isPIB,
    pathname,
    urlSlug,
  } = settings;

  // Turn off scrollbar if this isn't the PIB. We have to do it with a class
  // rather than directly changeding the style with js because you can't access
  // pseudo-elements with js.
  if ( !isPIB ) {
    window.document.body.classList.add( 'noScroll' );
  }

  // Auth without user login first.
  useEffect( () => {
    auth.isLoggedIn()
      .then( ( data ) => {
        if ( data ) {
          if ( !isPIB ) {
            setIsLoggedIn( true );
            setSalespersonId( data.user_uuid );
            setSalespersonNid( data.user_nid );
          }
          setIsAuthed( true );
        } else {
          auth.noLoginAuth()
            .then( ( data2 ) => {
              if ( !data2 ) {
                setIsAuthed( false );
              } else {
                setIsAuthed( true );
              }
            } )
            .catch( () => {
              setIsAuthed( false );
            } );
        }
      } )
      .catch( () => {
        auth.noLoginAuth()
          .then( ( data3 ) => {
            if ( !data3 ) {
              setIsAuthed( false );
            } else {
              setIsAuthed( true );
            }
          } )
          .catch( () => {
            setIsAuthed( false );
          } );
      } );
  }, [ isPIB ] );

  // Once authed, get and set all config data.
  useEffect( () => {
    const fetchData = async () => {
      const url = `/your_tour_api/config/${urlSlug}`;
      const communityData = await auth.fetchWithAuthentication( url, 'GET', null );
      if ( communityData && !communityData.error ) {
        setMapConfig( communityData?.map );
        setYtConfig( communityData?.yt );
        setCrmConfig( communityData?.crm );
        setFeatureFlags( communityData?.yt?.features );
        setDefaultLevelOfCareUuid( communityData?.yt?.defaultLevelOfCare );
        setDocumentStyleProperties(
          window,
          communityData?.yt?.primaryColor,
          communityData?.yt?.secondaryColor,
        );
        const rawContainerStyle = createContainerStyleMarkup(
          communityData?.yt?.primaryColor,
          communityData?.yt?.secondaryColor,
        );
        setContainerStyle( rawContainerStyle );
      } else {
        // setAppStatus( 'error' );
      }
    };
    if ( isAuthed ) {
      fetchData();
    }
  }, [ isAuthed ] );

  // Check that authed config allows login and this page needs login.
  useEffect( () => {
    if ( settings && featureFlags ) {
      const loginAccess = checkIfLoginNeeded( settings, featureFlags );
      setAllowLoginAccess( loginAccess );
    }
  }, [ settings,
    featureFlags ] );

  // Once authed without login, check if we should ask user to login.
  // If so, reauth as logged in user.
  useEffect( () => {
    if ( !allowLoginAccess || !isAuthed ) {
      setIsLoggedIn( false );
      setSalespersonId( null );
    } else if ( !isLoggedIn ) {
      setIsLoggingIn( true );
      // If the user is anon authed, the isLoggedIn will be true.
      const url = new URL( window.location );
      const params = new URLSearchParams( url.search );
      const code = params.get( 'code' );

      if ( !code ) {
        // Triggers redirect to Drupal with auth creds.
        // Sets state and code verifier in cookie.
        auth.preLogin();
      } else if ( code ) {
        // Returning from drupal with subdomain param in state and auth code.
        // Login again.
        auth.login( code )
          .then( ( data ) => {
            setIsLoggedIn( true );
            setSalespersonId( data.user_uuid );
            setSalespersonNid( data.user_nid );
            // Remove code params from url so user sees a clean url.
            history.push( '/' );
            setIsLoggingIn( false );
          } )
          .catch( () => {
            // This code could be stale. Remove code params so auth is re
            // attempted.
            history.push( '/' );
            setIsLoggedIn( false );
            setSalespersonId( null );
            setSalespersonNid( null );
            setIsLoggingIn( false );
          } );
      }
    }
  }, [ isAuthed,
    allowLoginAccess ] );

  // When the user has selected to display fees, set a body class to control
  // display fee visibility.
  const displayModalPricing = () => {
    // Set a variable in localStorage when the user displays modal pricing.
    const EXPIRATION_TIME = 2 * 60 * 60 * 1000; // 2 hours in milliseconds.

    const now = new Date().getTime();
    const userDisplayedFeesSetting = {
      value: 'true',
      expiration: now + EXPIRATION_TIME,
    };

    localStorage.setItem( 'userDisplayedFees', JSON.stringify( userDisplayedFeesSetting ) );

    // Add a class to the body dom element.
    document.body.classList.add( 'toggleDisplayFees' );

    // Add class body after 2 seconds. T
    const timer = setTimeout( () => {
      document.body.classList.add( 'toggleDisplayFees__finished' );
    }, 2000 );

    return () => clearTimeout( timer );
  };

  // Check for localStorage of whether user has displayed fees.
  useEffect( () => {
    const displayedFees = JSON.parse( localStorage.getItem( 'userDisplayedFees' ) );

    if ( displayedFees !== null ) {
      const now = new Date().getTime();
      if ( now < displayedFees.expiration ) {
        // Setting is still valid, run function to add body classes.
        displayModalPricing();
      }
    }
  }, [] );

  // Set config that will be used by configcontext
  useEffect( () => {
    if ( ytConfig ) {
      ytConfig.isPIB = isPIB;
      setYtConfig( ytConfig );
    }
  }, [ ytConfig, isPIB ] );

  // Using useMemo to memoize the value object passed to ConfigContext.Provider
  // improves performance and readability by preventing unnecessary re-renders
  // of consuming components.
  const contextMemo = useMemo( () => ( {
    config: ytConfig,
    mapConfig,
    crmConfig,
    settings,
    personalizationActive,
    setPersonalizationActive,
    featureFlags,
    isKiosk,
    isAuthed,
    unitHasDisplayableFees,
    setUnitHasDisplayableFees,
    displayModalPricing,
    salespersonId,
    salespersonNid,
    salespersonName,
    setSalespersonName,
    isLoggedIn,
    setIsLoggedIn,
    appStatus,
    setAppStatus,
  } ), [
    ytConfig,
    crmConfig,
    mapConfig,
    settings,
    personalizationActive,
    featureFlags,
    isKiosk,
    isAuthed,
    unitHasDisplayableFees,
    displayModalPricing,
    salespersonId,
    salespersonNid,
    salespersonName,
    isLoggedIn,
    appStatus
  ] );

  return (
    <CacheBuster>
      {( { loading, isLatestVersion, refreshCacheAndReload } ) => {
        if ( loading ) return null;
        if ( !loading && !isLatestVersion ) {
          refreshCacheAndReload();
        }
        if ( pathname === '/forward' ) {
          return null;
        }
        return (
          <ConfigContext.Provider
            value={contextMemo}
          >
            <div className="container" style={containerStyle}>

              <AppSwitchboard
                isAuthed={isAuthed}
                allowLoginAccess={allowLoginAccess}
                isLoggedIn={isLoggedIn}
                isLoggingIn={isLoggingIn}
              />

            </div>
          </ConfigContext.Provider>
        );
      }}
    </CacheBuster>
  );
};

export default withRouter( App );
