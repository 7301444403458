import React, { useEffect, useContext, useState } from 'react';

import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Cookies from 'universal-cookie';
import personalizationValidationSchema from '../Validation/PersonalizationValidation';
import {
  compareIncomingCrmDataToFormValues,
  updateNewResidentCookieAfterResidentUpdate,
  updateExistingResidentCookieAfterResidentUpdate,
  conditionallyPostCoupleToDrupal,
  conditionallyPatchCoupleInDrupal,
  processFormDataUsingIntent,
  createPersonAttributeDataUsingIntent,
  createInitialResidentCookieData,
  createInitialFavoritesCookieData,
  createInitialMediaFavoritesCookieData,
} from '../FormHelpers';
import AccountLookupStep from '../FormSteps/AccountLookupStep';
import AccountNotFoundStep from '../FormSteps/AccountNotFoundStep';
import AccountFoundStep from '../FormSteps/AccountFoundStep';
import AccountResidentSelectionStep from '../FormSteps/AccountResidentSelectionStep';
import EndPersonalizationWarning from '../FormSteps/EndPersonalizationWarning';
import EndPersonalization from '../FormSteps/EndPersonalization';
import {
  fetchResidentsFromCrm,
  fetchResidentsFromDrupal,
  patchResidentInDrupal,
} from '../../../Resident/ResidentHelpers';
import {
  combineAndFormatYtSessionsForDrupal,
  removeYtCookies,
} from '../../PersonalizationHelpers';
import ConfigContext from '../../../../utils/ConfigContext/ConfigContext';
import { toIsoString } from '../../../../utils/common';

const cookies = new Cookies();

const PersonalizationForm = ( {
  beginSession,
  updateModalSize,
  closeModal,
  currentStep,
  setCurrentStep,
  setHasCRMResults,
  cookieDataDump,
  setCookieDataDump,
  setPersonalizationActive,
  setFormIsSubmitting,
} ) => {
  const [ crmDataState, setCrmDataState ] = useState( '' );
  const [ crmData, setCrmData ] = useState( [] );
  const [ residentSelected, setResidentSelected ] = useState( '' );
  const [ dataIsSent, setDataIsSent ] = useState( false );

  // We're doing '-1' here to line up with the array keys of the validation
  // array.
  const currentValidationSchema = personalizationValidationSchema[currentStep - 1];

  const {
    config,
    crmConfig,
    personalizationActive,
    salespersonNid
  } = useContext( ConfigContext );

  const {
    urlSlug,
    communityUuid,
    communityNid,
  } = config;

  const { machineName } = crmConfig;

  // Anytime personalization is stopped, clear out the state values.
  useEffect( () => {
    if ( !personalizationActive ) {
      setCrmDataState( '' );
      setCrmData( [] );
      setResidentSelected( '' );
    }
  }, [ personalizationActive ] );

  /**
   * Form submission functionality that begins personalization s.
   *
   * @param {Array} values
   *   Formik's form values array.
   * @param {Array} updatedFormData
   *   An array of any form data that has been updated after being returned
   *   from Drupal. This way, we know if updated data needs to be pushed back
   *   to Drupal.
   * @param {string} formIntent
   * @param {object} selectedResidentCrmData
   */
  async function submitPersonalization(
    values,
    updatedFormData,
    formIntent,
    selectedResidentCrmData,
  ) {
    // Format resident data to be sent to Drupal based on form changes.
    let {
      person1AttributesData,
      person2AttributesData,
    } = createPersonAttributeDataUsingIntent(
      values,
      formIntent,
      selectedResidentCrmData,
      updatedFormData,
    );

    // Change data according to intent.
    const {
      apiActions,
      uuids,
      updatedPerson2AttributesData,
    } = processFormDataUsingIntent(
      values,
      formIntent,
      person1AttributesData,
      person2AttributesData,
    );

    person2AttributesData = updatedPerson2AttributesData;

    // Gather the data and set the initial resident cookie.
    let residentCookieData = createInitialResidentCookieData(
      uuids,
      values,
      selectedResidentCrmData,
      urlSlug,
      salespersonNid,
    );

    // Gather the data and set the initial favorites cookie.
    const favoritesCookieData = createInitialFavoritesCookieData( selectedResidentCrmData );
    cookies.set( 'favorites', favoritesCookieData, {
      path: '/',
      maxAge: 14400, // 4 hour cookie expiration.
      domain: window.location.hostname,
    } );

    // Gather the data and set the initial media favorites cookie.
    const mediaFavoritesCookieData = createInitialMediaFavoritesCookieData(
      selectedResidentCrmData,
    );
    cookies.set( 'media_favorites', mediaFavoritesCookieData, {
      path: '/',
      maxAge: 14400, // 4 hour cookie expiration.
      domain: window.location.hostname,
    } );

    // Add all sessions to data that will update Drupal.
    // Don't patch other CRMs - we'll do that at the end of personalization.
    if ( machineName === 'drupal' ) {
      // Combine current and past yt sessions and format them for Drupal API.
      const formattedSessions = await combineAndFormatYtSessionsForDrupal( residentCookieData );
      person1AttributesData = Object.assign( person1AttributesData, formattedSessions );
    }

    // If we're posting Person 1, the session start will be sent with the post anyway.
    // If we aren't posting Person 1, then we need to patch them with the new start time.
    if ( !apiActions.postPerson1 && machineName === 'drupal' ) {
      apiActions.patchPerson1 = true;
    }

    // Post individual to Drupal if the api action says to. This should only
    // happen for people with Drupal as their CRM.
    const newResidents = await conditionallyPostCoupleToDrupal(
      person1AttributesData,
      person2AttributesData,
      apiActions,
      communityUuid,
    );

    // Update data based on posting above changes.
    if ( newResidents && newResidents[0] && newResidents[0].data ) {
      const person1Id = newResidents[0].data.id;
      uuids.person1 = person1Id;
      uuids.person2Spouse = person1Id;

      const newYtSession = newResidents[0]?.data?.attributes?.field_yt_sessions[0];
      if ( newYtSession ) {
        residentCookieData.yourtour_active_session = newYtSession;
        const formattedSessions = await combineAndFormatYtSessionsForDrupal( residentCookieData );
        person1AttributesData = Object.assign( person1AttributesData, formattedSessions );
      }
    }

    if ( newResidents && newResidents[1] && newResidents[1].data ) {
      const person2Id = newResidents[1].data.id;
      uuids.person2 = person2Id;
      uuids.person1Spouse = person2Id;
    }

    // Update individuals with changes. This should only happen for people with
    // Drupal as their CRM.
    const patchedResidents = await conditionallyPatchCoupleInDrupal(
      person1AttributesData,
      person2AttributesData,
      uuids,
      apiActions,
      communityUuid,
    );

    // For new residents, update cookie if there are cookie-related changes.
    if (
      newResidents?.length > 0
      && (
        !residentCookieData.drupal_uuid
        || residentCookieData.drupal_uuid === ''
        || residentCookieData.my_favorites_page === ''
      )
    ) {
      // Create new cookie data from updated residents.
      residentCookieData = updateNewResidentCookieAfterResidentUpdate(
        newResidents,
        residentCookieData,
        urlSlug,
      );
    } else if ( machineName === 'drupal' ) {
      // For existing residents, we need to add the current session's newly
      // created uuid to the cookie. We only do this for Drupal because only
      // residents with that CRM have been added to Drupal yet.
      residentCookieData = updateExistingResidentCookieAfterResidentUpdate(
        patchedResidents,
        residentCookieData,
      );
    }

    // Set new resident cookie.
    cookies.set( 'resident', residentCookieData, {
      path: '/',
      maxAge: 14400, // 4 hour cookie expiration.
      domain: window.location.hostname,
    } );

    beginSession();
    closeModal();
  }

  /**
   * Step 1 submit.
   *
   * @param {Array} values
   * @param {Object} actions
   */
  async function step1Submit( values, actions ) {
    // If we're on step 1 when the form is submitted, submit the user entered
    // to CRM and retrieve resident info.
    updateModalSize( 'small' );

    // Step 1 of the form which look in CRM for a resident that matches
    // the first name, last name, and/or email address entered.
    const incomingCrmData = await fetchResidentsFromCrm(
      machineName,
      values.email,
      values.first_name,
      values.last_name,
      urlSlug,
      communityNid,
    );

    // If record is found.
    if ( incomingCrmData?.length ) {
      // When we find only one record based on the user's form entry.
      if ( incomingCrmData?.length === 1 ) {
        const userData = incomingCrmData[0];
        actions.setFieldValue( 'drupal_uuid', userData?.drupalUuid );
        actions.setFieldValue( 'crm_individual_id', userData?.crmIndividualId );
        actions.setFieldValue( 'crm_prospect_id', userData?.crmProspectId );
        actions.setFieldValue( 'first_name', userData?.firstName ?? '' );
        actions.setFieldValue( 'last_name', userData?.lastName ?? '' );
        actions.setFieldValue( 'email', userData?.email ?? '' );
        actions.setFieldValue(
          'my_favorites_page',
          userData?.myFavoritesPage,
        );
        actions.setFieldValue(
          'person2_drupal_uuid',
          userData?.person2DrupalUuid,
        );
        actions.setFieldValue(
          'person2_crm_individual_id',
          userData?.person2CrmIndividualId,
        );
        actions.setFieldValue(
          'person2_first_name',
          userData?.person2FirstName ?? '',
        );
        actions.setFieldValue(
          'person2_last_name',
          userData?.person2LastName ?? '',
        );
        actions.setFieldValue( 'person2_email', userData?.person2Email ?? '' );

        // Found a record & drupal returned data for the user.
        if ( userData.firstName || userData.lastName || userData.email ) {
          setCrmDataState( 'found' );
          // Advance to next form step.
          setCurrentStep( currentStep + 1 );
        }

        // Found Record but crm has no data for the user.
        else {
          setCrmDataState( 'foundNoInfo' );
          setResidentSelected( '' );
          // Advance to next form step.
          setCurrentStep( currentStep + 1 );
        }
      } else {
        // Advance to next form step.
        setCurrentStep( currentStep + 1 );
        setHasCRMResults( true );
      }
      setCrmData( incomingCrmData );
      updateModalSize( 'medium' );
    } else {
      // Clear out existing data from previous searches.
      setCrmData( [] );

      // Reset resident selected from previous searches.
      setResidentSelected( '' );

      // Did we not find anything because there weren't matches or because the
      // CRM isn't responding?
      // We didn't find any matching users in the CRM, so advance to the 'Create
      // new' form step.
      setCrmDataState( 'notFound' );

      // Advance to next form step.
      setCurrentStep( 3 );

      // Populate the 'Create new' form with the data the user entered on step 1.
      actions.setFieldValue( 'first_name', values.first_name );
      actions.setFieldValue( 'last_name', values.last_name );
      actions.setFieldValue( 'email', values.email );

      updateModalSize( 'small' );
    }

    // Reset these values to the next form isn't showing validation states.
    actions.setTouched( {} );
    actions.setSubmitting( false );
  }

  /**
   * Step 2 submit.
   *
   * @param {Array} values
   * @param {Object} actions
   */
  async function step2Submit( incomingValues, actions ) {
    const values = incomingValues;
    // If the Crm is Drupal, let people edit the person.
    // Advance to the edit page.
    if ( machineName === 'drupal') {
      if ( values.action === 'continue' ) {
        const selectedResident = crmData[values.residentSelection];
        // Set form fields with CRM data. If data doesn't exist, it will be a blank
        // field.
        if ( selectedResident ) {
          setCrmDataState( 'found' );
          actions.setFieldValue( 'drupal_uuid', selectedResident.drupalUuid );
          actions.setFieldValue( 'crm_individual_id', selectedResident.crmIndividualId );
          actions.setFieldValue( 'crm_prospect_id', selectedResident.crmProspectId );
          actions.setFieldValue( 'first_name', selectedResident.firstName ?? '' );
          actions.setFieldValue( 'last_name', selectedResident.lastName ?? '' );
          actions.setFieldValue( 'email', selectedResident.email ?? '' );
          actions.setFieldValue( 'my_favorites_page', selectedResident.myFavoritesPage );
          actions.setFieldValue(
            'person2_drupal_uuid',
            selectedResident.person2DrupalUuid ?? '',
          );
          actions.setFieldValue(
            'person2_first_name',
            selectedResident.person2FirstName ?? '',
          );
          actions.setFieldValue(
            'person2_last_name',
            selectedResident.person2LastName ?? '',
          );
          actions.setFieldValue(
            'person2_email',
            selectedResident.person2Email ?? '',
          );
        }
      } else if ( values.action === 'create new' ) {
      // If the user clicked 'Created new', reset the form values.
        actions.setFieldValue( 'drupal_uuid', '' );
        actions.setFieldValue( 'first_name', '' );
        actions.setFieldValue( 'last_name', '' );
        actions.setFieldValue( 'email', '' );
        actions.setFieldValue( 'my_favorites_page', '' );
        actions.setFieldValue( 'person2_drupal_uuid', '' );
        actions.setFieldValue( 'person2_first_name', '' );
        actions.setFieldValue( 'person2_last_name', '' );
        actions.setFieldValue( 'person2_email', '' );
        actions.setFieldValue( 'resident', '' );
        setResidentSelected( '' );

        // If the user got a result but they've decided to make a new person, we
        // need to reset this crmDataState or else the new person won't be submitted.
        setCrmDataState( 'notFound' );
      }

      // Advance to last form step.
      setCurrentStep( currentStep + 2 );
      actions.setTouched( {} );
      actions.setSubmitting( false );
    }
    // If the CRM is Enquire or Sherpa, they aren't allowed to edit the person.
    // Welcome Home allows us to edit, but I'm moving that functionality to the
    // next release.
    // Start the personalization session.
    else {
      const selectedResident = crmData[values.residentSelection];
      if ( selectedResident ) {
        values.drupal_uuid = selectedResident.drupalUuid;
        values.first_name = selectedResident.firstName ?? '';
        values.last_name = selectedResident.lastName ?? '';
        values.email = selectedResident.email ?? '';
        values.my_favorites_page = selectedResident.myFavoritesPage ?? '';
        values.person2_drupal_uuid = selectedResident.person2drupalUuid;
        values.person2_crm_individual_id = selectedResident.person2firstName ?? '';
        values.person2_first_name = selectedResident.person2FirstName ?? '';
        values.person2_last_name = selectedResident.person2LastName ?? '';
        values.person2_email = selectedResident.person2Email ?? '';
      }

      let formIntent = '';
      // Find out if the person selected has already been added to Drupal
      // by finding people with the same CRM id.
      let person1Matched = false;
      const person1CrmId = selectedResident?.crmIndividualId;

      if ( person1CrmId ) {
        const person1DrupalMatchData = await fetchResidentsFromDrupal(
          null,
          null,
          null,
          person1CrmId,
          communityNid,
        );

        if ( person1DrupalMatchData && person1DrupalMatchData.length > 0 ) {
          // @TODO - figure out what to do if there are multiple matches
          // For now, just take the first one.
          // Add Drupal id to selected resident.
          const { 0: person1Match } = person1DrupalMatchData;
          selectedResident.drupalUuid = person1Match.drupalUuid;
          selectedResident.crmIndividualId = person1Match.crmIndividualId;
          selectedResident.crmProspectId = person1Match.crmProspectId;
          selectedResident.drupalNid = person1Match.drupalNid;
          selectedResident.yourTourSessionsUuid = person1Match.yourTourSessionsUuid;
          selectedResident.favoriteAmenities = person1Match.favoriteAmenities;
          selectedResident.favoriteFloorPlans = person1Match.favoriteFloorPlans;
          selectedResident.favoriteMedia = person1Match.favoriteMedia;
          selectedResident.myFavoritesPage = person1Match.myFavoritesPage;
          values.drupal_uuid = person1Match.drupalUuid;
          values.drupal_nid = person1Match.drupalNid;
          person1Matched = true;
        }
      }
      // CRM person not found in Drupal.
      if ( !person1Matched ) {
        formIntent = 'New Resident(s)';
      } else {
        formIntent = 'No Changes';
        // WE AREN'T UPDATING ENQUIRE OR SHERPA DATA RIGHT NOW
        // check if person 1 has changed from incoming crm info from drupal, not enquire
        // let compareData = compareIncomingCrmDataToFormValues(person1MatchData, values);
        // formIntent = compareData.formIntent;
        // updatedFormData = compareData.updatedFormData;
      }

      submitPersonalization( values, {}, formIntent, selectedResident );
    }
  }

  /**
   * Step 3 submit.
   *
   * @param {Object} actions
   */
  const step3Submit = ( actions ) => {
    // Advance to last form step.
    setCurrentStep( currentStep + 1 );
    actions.setTouched( {} );
    actions.setSubmitting( false );
  };

  /**
   * Step 4 submit.
   *
   * @param {Array} values
   */
  async function step4Submit( incomingValues ) {
    const values = incomingValues;

    // Disable button until data is sent from form to Drupal and CRM

    // If we're on the 2nd step (final step), do final submission which
    // begins session.
    let updatedFormData = {};

    // Track form intent. Either 'New Resident' or 'Update Resident'.
    let formIntent = '';

    let selectedResident = null;

    // If `residentSelected` is true, then a resident was found from the CRM.
    if ( residentSelected !== '' ) {
      // Get the resident object based on the user selection of residents
      // from the crm.
      selectedResident = crmData[residentSelected];
      // Community does not use Drupal as CRM
      if ( machineName !== 'drupal' ) {
        // Find out if the person selected  has already been added to Drupal
        // by finding people with the same CRM id.
        const person1CrmId = selectedResident?.crmIndividualId;
        let person1Matched = false;

        if ( person1CrmId ) {
          const person1DrupalMatchData = await fetchResidentsFromDrupal(
            null,
            null,
            null,
            person1CrmId,
            communityNid,
          );

          if ( person1DrupalMatchData && person1DrupalMatchData.length > 0 ) {
            // @TODO - Figure out what to do if there are multiple matches.
            // For now, just take the first one.
            // Add drupal id to selected resident.
            const { 0: person1DrupalMatch } = person1DrupalMatchData;

            selectedResident.drupalUuid = person1DrupalMatch.drupalUuid;
            selectedResident.drupalNid = person1DrupalMatch.drupalNid;
            selectedResident.yourTourSessions = person1DrupalMatch.yourTourSessions;
            selectedResident.favoriteAmenities = person1DrupalMatch.favoriteAmenities;
            selectedResident.favoriteFloorPlans = person1DrupalMatch.favoriteFloorPlans;
            selectedResident.favoriteMedia = person1DrupalMatch.favoriteMedia;
            selectedResident.myFavoritesPage = person1DrupalMatch.myFavoritesPage;
            values.drupal_uuid = person1DrupalMatch.drupalUuid;
            values.drupal_nid = person1DrupalMatch.drupalNid;
            person1Matched = true;
          }
        }

        // CRM person is not in Drupal.
        if ( !person1Matched ) {
          formIntent = 'New Resident(s)';
        } else {
          formIntent = 'No Changes';
          // WE AREN'T UPDATING ENQUIRE DATA RIGHT NOW
          // Check if person 1 has changed from incoming crm info from Drupal, not Enquire.
          // let compareData = compareIncomingCrmDataToFormValues(person1MatchData, values);
          // formIntent = compareData.formIntent;
          // updatedFormData = compareData.updatedFormData;
        }
      } else {
        // Drupal is the CRM.
        const compareData = compareIncomingCrmDataToFormValues(
          selectedResident,
          values,
        );
        formIntent = compareData.formIntent;
        updatedFormData = compareData.updatedFormData;
      }
    } else {
      // If the user got a result but they've decided to make a new person, we
      // need to reset this crmDataState or else the new person won't be submitted.
      setCrmDataState( 'notFound' );
      formIntent = 'New Resident(s)';
      values.resident = '';
    }

    submitPersonalization(
      values,
      updatedFormData,
      formIntent,
      selectedResident,
    );
  }

  const updateSessionsDataAndPatchResidentInCRM = async (
    residentCookieData,
    favoritesCookieData,
    mediaFavoritesCookieData,
  ) => {
    // Update session data.
    const residentCookie = cookies.get( 'resident' );
    const now = new Date();
    const iso = toIsoString( now );
    residentCookie.yourtour_active_session.end_value = iso;

    const attributesData = await combineAndFormatYtSessionsForDrupal( residentCookie );

    // We update non-Drupal favorites at the end of the session so we don't live post
    // changes in favorites and session times.
    const relationshipsData = {};
    if ( machineName !== 'drupal' ) {
      // Assemble non-media favorites.

      const fpFavorites = favoritesCookieData.favorites.floor_plan;
      const fpFavoriteRelationships = [];
      if ( favoritesCookieData.favorites?.floor_plan ) {
        // eslint-disable-next-line no-restricted-syntax
        for ( const fpFavorite of fpFavorites ) {
          const fpFavoriteRelationship = {
            type: 'node--floor_plan',
            id: fpFavorite,
          };
          fpFavoriteRelationships.push( fpFavoriteRelationship );
        }
      }

      relationshipsData.field_favorite_floor_plans = {
        data: fpFavoriteRelationships,
      };

      const pFavorites = favoritesCookieData.favorites.place;
      const pFavoriteRelationships = [];
      if ( favoritesCookieData.favorites?.place ) {
        // eslint-disable-next-line no-restricted-syntax
        for ( const pFavorite of pFavorites ) {
          const pFavoriteRelationship = {
            type: 'node--place',
            id: pFavorite,
          };
          pFavoriteRelationships.push( pFavoriteRelationship );
        }
      }

      relationshipsData.field_favorite_amenities = {
        data: pFavoriteRelationships,
      };

      // Assemble media favorites.

      const mFavoriteRelationships = [];

      const vFavorites = mediaFavoritesCookieData?.favorites?.video;
      if ( vFavorites ) {
        // eslint-disable-next-line no-restricted-syntax
        for ( const vFavorite of vFavorites ) {
          const vFavoriteRelationship = {
            type: 'node--video',
            id: vFavorite,
          };
          mFavoriteRelationships.push( vFavoriteRelationship );
        }
      }

      const vtFavorites = mediaFavoritesCookieData?.favorites?.virtual_tour;
      if ( vtFavorites ) {
        // eslint-disable-next-line no-restricted-syntax
        for ( const vtFavorite of vtFavorites ) {
          const vtFavoriteRelationship = {
            type: 'node--virtual_tour',
            id: vtFavorite,
          };
          mFavoriteRelationships.push( vtFavoriteRelationship );
        }
      }

      const pgFavorites = mediaFavoritesCookieData?.favorites?.photo_gallery;
      if ( pgFavorites ) {
        // eslint-disable-next-line no-restricted-syntax
        for ( const pgFavorite of pgFavorites ) {
          const pgFavoriteRelationship = {
            type: 'node--photo_gallery',
            id: pgFavorite,
          };
          mFavoriteRelationships.push( pgFavoriteRelationship );
        }
      }

      relationshipsData.field_favorite_media = {
        data: mFavoriteRelationships,
      };
    }

    const response = await patchResidentInDrupal(
      residentCookieData.drupal_uuid,
      attributesData,
      null,
      relationshipsData,
    );

    return response;
  };

  /**
   * End personalization warning submit.
   */
  async function endPersonalizationWarningSubmit() {
    const residentCookieData = cookies.get( 'resident' );
    const favoritesCookieData = cookies.get( 'favorites' );
    const mediaFavoritesCookieData = cookies.get( 'media_favorites' );

    // Save cookie data for session ended modal.
    setCookieDataDump( residentCookieData );

    // Update sessions data and send all actions back to Drupal.
    const response = await updateSessionsDataAndPatchResidentInCRM(
      residentCookieData,
      favoritesCookieData,
      mediaFavoritesCookieData,
    );
    removeYtCookies( cookies );
    setHasCRMResults( false );
    setPersonalizationActive( false );
    setCurrentStep( 'endPersonalization' );
  }

  /**
   * Form submit handler that is fired as the user goes between the form steps.
   *
   * @param {Array} values
   * @param {Object} actions
   */
  async function handleSubmit( values, actions ) {
    switch ( currentStep ) {
      case 1:
        // If we're on step 1 when the form is submitted, submit the user entered
        // to CRM and retrieve resident info.
        await step1Submit( values, actions );
        break;
      case 2:
        await step2Submit( values, actions );
        break;
      case 3:
        step3Submit( actions );
        break;
      case 4:
        setDataIsSent( false );
        await step4Submit( values );
        setDataIsSent( true );
        break;
      case 'endPersonalizationWarning':
        await endPersonalizationWarningSubmit();
        break;
      default:
    }
  }

  return (
    <Formik
      // Initial Form Values.
      initialValues={{
        drupal_uuid: '',
        email: '',
        first_name: '',
        last_name: '',
        my_favorites_page: '',
        person2_drupal_uuid: '',
        person2_first_name: '',
        person2_last_name: '',
        person2_email: '',
        residentSelection: '',
      }}
      validationSchema={currentValidationSchema}
      onSubmit={handleSubmit}
    >
      {( {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      } ) => (
        <form className="personalization-form" onSubmit={handleSubmit}>
          {/* Based on step that we are on the form, render the correct form component. */}
          {/* User enteres first name, last name, and/or email. */}
          {currentStep === 1 && (
            <AccountLookupStep
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              isSubmitting={isSubmitting}
              setHasCRMResults={setHasCRMResults}
            />
          )}

          {/* Matches are returned and user must select user or make a new one. */}
          {currentStep === 2 && (
            <AccountResidentSelectionStep
              residentSelected={residentSelected}
              setResidentSelected={setResidentSelected}
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              crmData={crmData}
              setCurrentStep={setCurrentStep}
            />
          )}

          {currentStep === 3 && (
            <AccountNotFoundStep
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              crmDataState={crmDataState}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              setCurrentStep={setCurrentStep}
            />
          )}

          {currentStep === 4 && (
            <AccountFoundStep
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              crmDataState={crmDataState}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              dataIsSent={dataIsSent}
            />
          )}

          {currentStep === 'endPersonalizationWarning' && (
            <EndPersonalizationWarning
              isSubmitting={isSubmitting}
              setFormIsSubmitting={setFormIsSubmitting}
            />
          )}

          {currentStep === 'endPersonalization' && (
            <EndPersonalization cookieDataDump={cookieDataDump} />
          )}
        </form>
      )}
    </Formik>
  );
};

PersonalizationForm.propTypes = {
  beginSession: PropTypes.func.isRequired,
  updateModalSize: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  currentStep: PropTypes.oneOfType( [ PropTypes.string,
    PropTypes.number ] ).isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  setHasCRMResults: PropTypes.func.isRequired,
  cookieDataDump: PropTypes.shape( {
    drupal_uuid: PropTypes.string,
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    my_favorites_page: PropTypes.string,
    person2_drupal_uuid: PropTypes.string,
    person2_first_name: PropTypes.string,
    person2_last_name: PropTypes.string,
    person2_email: PropTypes.string,
    residentSelection: PropTypes.string,
  } ),
  setCookieDataDump: PropTypes.func.isRequired,
  setPersonalizationActive: PropTypes.func.isRequired,
  setFormIsSubmitting: PropTypes.func.isRequired,
};

PersonalizationForm.defaultProps = {
  cookieDataDump: null,
};

export default PersonalizationForm;
