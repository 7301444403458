import { toIsoString } from '../../utils/common';
import { getResidentYtSessions } from '../Resident/ResidentHelpers';

export function updateAndFormatActiveSessionData( session ) {
  const now = new Date();
  const iso = toIsoString( now );

  const formattedSession = {
    value: session.value,
    end_value: iso,
    uuid: session?.uuid || null,
    user_id: session?.user_id || null,
  };

  const attributesData = {
    field_yt_session: formattedSession,
  };

  return attributesData;
}

export async function combineAndFormatYtSessionsForDrupal( residentCookieData ) {
  let ytSessions = [];
  const currentSession = residentCookieData.yourtour_active_session;
  // If it is an existing resident.
  if ( residentCookieData?.drupal_uuid ) {
    ytSessions = await getResidentYtSessions( residentCookieData.drupal_uuid );

    console.log('currentSession', currentSession);
    console.log('ytSessions', ytSessions);

    const existingCurrentSession = ytSessions.findIndex(
      ( session ) => session.uuid === currentSession.uuid,
    );
    if ( existingCurrentSession !== -1 || null ) {
      ytSessions[existingCurrentSession] = currentSession;
    } else {
      ytSessions.push( currentSession );
    }
  } else {
    // If it is a new resident.
    ytSessions.push( currentSession );
  }

  const attributesData = {
    field_yt_sessions: ytSessions,
  };

  return attributesData;
}

export function updateActiveSessionDataForCookie( session ) {
  const updatedSession = session;
  const now = new Date();
  const iso = toIsoString( now );
  updatedSession.end_value = iso;
  return updatedSession;
}

export function removeYtCookies( cookies ) {
  // Clear cookies related to session.
  cookies.remove( 'resident', {
    path: '/',
    domain: window.location.hostname,
  } );
  cookies.remove( 'favorites', {
    path: '/',
    domain: window.location.hostname,
  } );
  cookies.remove( 'media_favorites', {
    path: '/',
    domain: window.location.hostname,
  } );
  cookies.remove( 'yourtour_sessions', {
    path: '/',
    domain: window.location.hostname,
  } );
}
