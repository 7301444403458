import { getNormalizedApiData } from '../../utils/ApiHelpers';
import { getAuthClient } from '../../utils/auth';
import { personalizationNormalizeDrupalData } from '../Personalization/PersonalizationNormalizedData';

const auth = getAuthClient();

/**
 * Fetches data from Drupal for Residents that match any of the params.
 *
 * @param {string} email
 *   User email.
 * @param {string} firstName
 *   User first name.
 * @param {string} lastName
 *   User last name.
 * @param {array} crmIds
 *   User(s) crm IDs.
 * @param {string} communityNid
 *  Community Drupal node id.
 *
 * @returns {Array}
 *   List of users that match search criteria.
*/
export async function fetchResidentsFromDrupal( email, firstName, lastName, crmId, communityNid ) {
  // Build filters based on filled out fields from the form.
  const filter = {};
  if ( email && email !== '' ) {
    filter.field_email = email;
  }
  if ( firstName && firstName !== '' ) {
    filter.field_first_name = firstName;
  }
  if ( lastName && lastName !== '' ) {
    filter.field_last_name = lastName;
  }
  if ( crmId && crmId !== '' ) {
    filter.field_crm_individual_id = crmId;
  }

  const includes = [
    'field_spouse',
    'field_favorite_floor_plans',
    'field_favorite_amenities',
    'field_favorite_media',
    'field_favorite_media.field_gallery_photos',
    'field_favorite_media.field_three_sixties',
  ];

  const request = {
    entityType: 'node',
    contentType: 'resident',
    params: {
      sort: 'title',
      include: includes.join( ',' ),
    },
    filter,
  };

  // Fetch data
  const residents = getNormalizedApiData(
    request,
    communityNid,
    personalizationNormalizeDrupalData,
  );

  return residents;
}

/**
 * Fetches data from third party CRMS via RESTAPI interface in Drupal for Residents that match
 * any of the params.
 *
 * @param {string} email
 *   User email.
 * @param {string} firstName
 *   User first name.
 * @param {string} lastName
 *   User last name.
 * @param {string} urlSlug
  *   Url slug for the community.
 *
 * @returns {Array}
 *   List of users that match search criteria.
*/
export async function fetchResidentsFromCrmViaDrupal( email, firstName, lastName, urlSlug ) {
  // return new Promise( async ( resolve, reject ) => {
  const queries = [];
  // Build queries based on filled out fields from the form.
  if ( email ) queries.push( `Email=${email}` );
  if ( firstName ) queries.push( `FirstName=${firstName}` );
  if ( lastName ) queries.push( `LastName=${lastName}` );

  let queryString = queries.join( '&' );
  if ( queryString ) {
    queryString = `?${queryString}`;
  }
  const url = `/third_party_apis/matching_prospects/${urlSlug}${queryString}`;

  const residents = await auth.fetchWithAuthentication( url, 'GET', null );

  return residents;
}

/**
  * Fetches data from Drupal for Residents that match any of the params.
  *
  * @param {string} email
  *  User email.
  * @param {string} firstName
  *  User first name.
  * @param {string} lastName
  *  User last name.
  * @param {string} urlSlug
  *  Url slug for the community.
  * @param {string} communityNid
  *  Community Drupal node id.
  *
  * @returns {Array}
  *   List of users that match search criteria.
*/
export async function fetchResidentsFromCrm(
  machineName,
  email,
  firstName,
  lastName,
  urlSlug,
  communityNid,
) {
  let residents = null;
  if ( machineName === 'drupal' ) {
    residents = await fetchResidentsFromDrupal(
      email,
      firstName,
      lastName,
      null,
      communityNid,
    );
  } else {
    residents = await fetchResidentsFromCrmViaDrupal(
      email,
      firstName,
      lastName,
      urlSlug,
    );
  }

  return residents;
}

/**
 * Patch resident in Drupal.
 *
 * '' for spouseUuid removes the relationshing to the current spouse.
 * null for spouseUuid is leaves the relationship as is.
 *
 * @param {String} id
 *    Drupal UUID.
 * @param {Object} attributesData
 *    Data structured as an attributes node for Drupal JSON API patching.
 * @param {String} spouseUuid
 *    Spouse Drupal UUID.
 * @param {Object} relationshipsData
 *    Data structured as an relationships node for Drupal JSON API patching.
 * @returns {Object}
 */
export async function patchResidentInDrupal( id, attributesData, spouseUuid, relationshipsData ) {
  if ( !id || !attributesData ) {
    // eslint-disable-next-line no-console
    console.error( 'Cannot patch resident. Missing id or attributesData.' );
    return {};
  }

  const data = {
    data: {
      type: 'node--resident',
      id,
    },
  };

  if ( Object.values( attributesData ).length > 0 ) {
    data.data.attributes = attributesData;
  }

  if ( spouseUuid && spouseUuid !== '' ) {
    const spouseRelationship = {
      field_spouse: {
        data: {
          type: 'node--resident',
          id: spouseUuid,
        },
      },
    };
    Object.assign( relationshipsData, spouseRelationship );
  } else if ( spouseUuid === '' ) {
    const spouseRelationship = {
      field_spouse: null,
    };
    Object.assign( relationshipsData, spouseRelationship );
  }

  if ( Object.values( relationshipsData ).length > 0 ) {
    data.data.relationships = relationshipsData;
  }

  const body = JSON.stringify( data );
  const updatedPerson = await auth.fetchWithAuthentication( `/jsonapi/node/resident/${id}`, 'PATCH', body );

  return updatedPerson;
}

/**
 * Form submit handler that is fired as the user goes between the form steps.
 *
 * @param {Object} attributesData
 *
 * @returns {Object}
 */
export async function postResidentInDrupal( attributesData, communityUuid ) {
  if ( !attributesData || !communityUuid ) {
    // eslint-disable-next-line no-console
    console.error( 'Cannot post resident. Missing attributesData or communityUuid.' );
    return {};
  }
  const data = {
    data: {
      type: 'node--resident',
      relationships: {
        og_audience: {
          data: {
            type: 'node--community',
            id: communityUuid,
          },
        },
      },
    },
  };

  if ( attributesData ) {
    data.data.attributes = attributesData;
  }

  const body = JSON.stringify( data );
  const resident = await auth.fetchWithAuthentication( '/jsonapi/node/resident', 'POST', body );

  return resident;
}

export function createResidentTitle( firstName, lastName, email ) {
  let title = 'YourTour Prospect';
  if ( ( firstName && firstName !== '' ) && ( lastName && lastName !== '' ) ) {
    title = `${firstName} ${lastName}`;
  } else if ( firstName && firstName !== '' ) {
    title = firstName;
  } else if ( lastName && lastName !== '' ) {
    title = lastName;
  } else if ( email && email !== '' ) {
    title = email;
  }
  return title;
}

/**
  * Get only the residnet's YourTour sessions times.
  *
  * @param {String} residentUuid
  *    Drupal UUID.
  *
  * @returns {Array}
  */
export async function getResidentYtSessions( residentUuid ) {
  const url = `/jsonapi/node/resident/${residentUuid}/?fields[node--resident]=field_yt_sessions`;
  const data = await auth.fetchWithAuthentication( url, 'GET', null );
  const sessions = data?.data?.attributes?.field_yt_sessions || [];

  return sessions;
};